if (document.querySelector('.product-single')) {
    handleMobileNavigationDropdown();
    handleTableScroll();
    handleTableTabs();
    productSingleCarousels();
    handleImageGalleryPosition();

    window.addEventListener('resize', handleImageGalleryPosition);
}

function handleMobileNavigationDropdown() {
    var toggleButton = document.querySelector('.product-single__nav-toggle');
    var parent = toggleButton.closest('.product-single__nav--mobile');
    var countElement = parent.querySelector('.product-single__nav-count--active');
    var textElement = parent.querySelector('.product-single__nav-link');
    var dropdown = parent.querySelector('.product-single__nav-dropdown');
    var dropdownLinks = dropdown.querySelectorAll('.product-single__nav-link');

    toggleButton.addEventListener('click', function() {
        parent.classList.toggle('active');
    });

    for (var i = 0; i < dropdownLinks.length; i++) {
        var link = dropdownLinks[i];
        
        (function(index, link) {
            link.addEventListener('click', function() {
                countElement.textContent = index + 1;
                textElement.textContent = link.textContent;
                parent.classList.remove('active');
            });
        })(i, link);
    }
}

function handleTableScroll() {
    document.addEventListener('click', function (event) {
        var button = event.target.closest('.product-single__table-nav-prev');

        if (button) {
            scrollTable(event.target, 'left');
            return;
        }

        button = event.target.closest('.product-single__table-nav-next');

        if (button) {
            scrollTable(event.target, 'right');
            return;
        }
    });

    function scrollTable(button, direction) {
        var scrollAmount = 180;
        var buttonContainer = button.closest('.product-single__table-nav');
        var tableContainer = buttonContainer.closest('.container').querySelector('.product-single__table-tabs-content.active');
        var table = null;

        if (tableContainer) {
            table = tableContainer.querySelector('.product-single__table');
        } else {
            table = buttonContainer.nextElementSibling;
        }

        var currentScroll = table.scrollLeft;
        
        if (direction === 'left') {
            table.scrollLeft = currentScroll - scrollAmount;
        } else if (direction === 'right') {
            table.scrollLeft = currentScroll + scrollAmount;
        }

        updateButtonState(table, buttonContainer);
    }

    function updateButtonState(table, buttonContainer) {
        var leftButton = buttonContainer.querySelector('.product-single__table-nav-prev');
        var rightButton = buttonContainer.querySelector('.product-single__table-nav-next');

        if (table.scrollLeft <= 0) {
            leftButton.disabled = true;
        } else {
            leftButton.disabled = false;
        }

        if (table.scrollLeft + table.clientWidth >= table.scrollWidth) {
            rightButton.disabled = true;
        } else {
            rightButton.disabled = false;
        }
    }
}

function handleTableTabs() {
    var tabButtons = document.querySelectorAll('.product-single__table-tabs-button');
    var toggleButton = document.querySelector('.product-single__table-tabs-toggle');

    if (toggleButton) {
        var parent = toggleButton.closest('.product-single__table-tabs-nav--mobile');
        var countElement = parent.querySelector('.product-single__table-tabs-count--active');
        var textElement = parent.querySelector('.product-single__table-tabs-text');
        var dropdown = parent.querySelector('.product-single__table-tabs-dropdown');

        toggleButton.addEventListener('click', function() {
            parent.classList.toggle('active');
        });
    }

    for (var i = 0; i < tabButtons.length; i++) {
        var button = tabButtons[i];
        
        (function(index, button) {
            button.addEventListener('click', function(e) {
                var currentActiveButton = document.querySelector('.product-single__table-tabs-button.active');
                var currentActiveTab = document.querySelector('.product-single__table-tabs-content.active');
                var activeIndex = e.target.getAttribute('data-table-tab-button');
                var newActiveTab = document.querySelector('.product-single__table-tabs-content[data-table-tab="' + activeIndex + '"]');

                currentActiveButton.classList.remove('active');
                currentActiveTab.classList.remove('active');
                
                e.target.classList.add('active');
                newActiveTab.classList.add('active');

                if (toggleButton) {
                    countElement.textContent = index + 1;
                    textElement.textContent = button.textContent;
                    parent.classList.remove('active');
                }
            });
        })(i, button);
    }
}

function productSingleCarousels() {
    var productAccessories = new Swiper('.product-single__accessories-carousel', {
        loop: false,
        slidesPerView: 4,
        spaceBetween: 16,
        grabCursor: true,
        watchOverflow: true,
        navigation: {
            nextEl: '.product-single__accessories-next',
            prevEl: '.product-single__accessories-prev',
        },
        pagination: {
            el: '.product-single__accessories-bullets',
            clickable: true,
            dynamicBullets: true,
        },
        breakpoints: {
            992: {
                slidesPerView: 3,
            },
            768: {
                slidesPerView: 2,
            },
            576: {
                slidesPerView: 1.3,
            },
            0: {
                slidesPerView: 1.3,
            }
        }
    });

    var productResources = new Swiper('.product-single__resources-carousel', {
        loop: false,
        slidesPerView: 4,
        spaceBetween: 16,
        grabCursor: true,
        watchOverflow: true,
        navigation: {
            nextEl: '.product-single__resources-next',
            prevEl: '.product-single__resources-prev',
        },
        pagination: {
            el: '.product-single__resources-bullets',
            clickable: true,
            dynamicBullets: true,
        },
        breakpoints: {
            992: {
                slidesPerView: 3,
            },
            768: {
                slidesPerView: 2,
            },
            576: {
                slidesPerView: 1.1,
            },
            0: {
                slidesPerView: 1.1,
            }
        }
    });

    var productRelated = new Swiper('.product-single__related-carousel', {
        loop: false,
        slidesPerView: 3.3,
        spaceBetween: 16,
        grabCursor: true,
        watchOverflow: true,
        navigation: {
            nextEl: '.product-single__related-next',
            prevEl: '.product-single__related-prev',
        },
        pagination: {
            el: '.product-single__related-bullets',
            clickable: true,
            dynamicBullets: true,
        },
        breakpoints: {
            992: {
                slidesPerView: 2.2,
            },
            768: {
                slidesPerView: 2.2,
            },
            576: {
                slidesPerView: 1.3,
            },
            0: {
                slidesPerView: 1.3,
            }
        }
    });
}

function handleImageGalleryPosition() {
    if (window.innerWidth < 768) {
        var carousel = document.querySelector('.product-single__carousel-wrapper .product-carousel-container');
        var parent = document.querySelector('.product-single__carousel-wrapper--mobile');

        if (carousel) {
            parent.appendChild(carousel);
        }
    } else {
        var carousel = document.querySelector('.product-single__carousel-wrapper--mobile .product-carousel-container');
        var parent = document.querySelector('.product-single__carousel-wrapper');

        if (carousel) {
            parent.appendChild(carousel);
        }
    }
}
